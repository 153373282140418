import React from "react"
import Layout from "../components/layout"

const ContactPage = ({ pageContext }) => (
  <Layout title="Contact Us" navItems={pageContext.categories}>
    <div className="uk-section-xsmall">
      <div className="uk-container uk-container-large uk-text-center">
        <h1>Contact Us</h1>
        <p></p>

        <iframe
          frameBorder="0"
          src="https://go.hts.com/myvrvdrive.com"
          style={{ height: 980, width: "100%" }}
        ></iframe>
      </div>
    </div>
  </Layout>
)

export default ContactPage
